<template>
	<div class="location">
		<div class="amap" id='containerMap'></div>
		<div class="affirmBtn" v-show="!hideBtn" @click="affirmAddress">确认</div>
		<div class="locationIcon" @click="getLocation">
			<van-icon name="aim" />
		</div>
		<div class="list">
			<div class="hearderSearch">
				<van-field type="text" @focus="hideBtn = true" @blur="hideBtn = false" v-model="pageQuery.address" @input="inputStatus" placeholder="请输入" class="input"/>
				<span class="searchBtn" @click="search">搜索</span>
			</div>
			<div id="pickerAuto" class="pickerAuto"></div>
			<div class="pois">
				<div class="poi_li" v-for="(item, index) in poiList" :key='index' @click="handPoi(item, index)">
					<div class="poiInfo">
						<img src="@/assets/rescueImgs/s_icon.png" alt="">
						<div class="info">
							<div :class="['title', activePOIIndex == index ? 'activePOI' : '']">{{ item.name }}</div>
							<div :class="['address', activePOIIndex == index ? 'activePOI' : '']">
								{{ item.pname ? item.pname : pageQuery.province }}{{ item.cityname ? item.cityname : pageQuery.city }}{{ item.adname ? item.adname : pageQuery.district }}{{ item.address }}
							</div>
						</div>
					</div>
					<div class="status">
						<img v-if='activePOIIndex == index' src="@/assets/rescueImgs/poiIcon.png" alt="">
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { saveLocation } from '@/apis/checkList';
import { getGeoLocation } from '@/utils/index';
export default {
	data(){
		return {
			zoom: 15,
			pageQuery: {
				address: "",
				lat: 0,
				lng: 0,
				type: 1,
				province: '',			//省
				city: '全国',			//市
				district: '',			//区
			},
			map: null,
			marker_startIcon: require('@/assets/rescueImgs/marker_start.png'),
			placeSearch: null,
			poiList: [],
			activePOIIndex: 0,
			marker: [],
			positionPicker: null,
			handPOIStatus: true,
			inputChange: false,
			order: '',				//客户h5修改地址
			hideBtn: false
		}
	},
	created(){
		let query = this.$route.query;
		this.order = query.order ? query.order : '';
		this.pageQuery.address = query.address;
		this.pageQuery.city = query.city;
		this.pageQuery.type = query.type;
		this.pageQuery.lng = query.lng != 0 ? Number(query.lng) : 114.131764;
		this.pageQuery.lat = query.lat != 0 ? Number(query.lat) : 22.548171;
		this.handPOIStatus = query.address ? true : false;
	},
	mounted(){
		this.initLocMap();
	},
	methods: {
		inputStatus(){
			this.inputChange = true
		},
		// 当前定位
        getLocation(){
            this.$toast.loading({
				message: '定位中...',
				forbidClick: true, 
				duration: 0
			})
            getGeoLocation().then((res)=>{
				this.pageQuery.address = res.address;
				this.pageQuery.province = res.province;
				this.pageQuery.city = res.city;
				this.pageQuery.district = res.district;
				this.pageQuery.lng = res.lng;
				this.pageQuery.lat = res.lat;
				this.POISearch();
				this.$toast.clear();
            }).catch((err)=>{
                this.$toast.clear();
                this.$toast(err);
            })
        },
		initLocMap(){
			let that = this;
			AMapUI.loadUI(["misc/PositionPicker"], function(PositionPicker) {
				that.map = new AMap.Map('containerMap', {
					zoom: that.zoom,
					// center: [that.pageQuery.lng, that.pageQuery.lat],
					resizeEnable: true
				})
				AMap.plugin('AMap.PlaceSearch', function () {
					that.placeSearch = new AMap.PlaceSearch({
						city: that.pageQuery.city,
						pageSize: 20,
						extensions: 'all'
					});
				})
				that.positionPicker = new PositionPicker({
					mode: "dragMap",
					map: that.map,
					iconStyle: {
						url: that.marker_startIcon,
						size: [26, 38],
						ancher: [13, 38],
					}
				})
				
				if(that.handPOIStatus){
					that.POISearch();
				}
				that.positionPicker.on("success", function(positionResult) {
					if(!that.handPOIStatus){
						let pois = positionResult.regeocode.pois;
						that.poiList = pois;
						that.activePOIIndex = 0;
						that.pageQuery.province = positionResult.regeocode.addressComponent.province;
						that.pageQuery.city = positionResult.regeocode.addressComponent.city;
						that.pageQuery.district = positionResult.regeocode.addressComponent.district;
						that.pageQuery.address =  pois.length != 0 ? pois[0].name : positionResult.address;
						that.pageQuery.lng = pois.length != 0 ? pois[0].location.lng : positionResult.position.lng;
						that.pageQuery.lat = pois.length != 0 ? pois[0].location.lat : positionResult.position.lat;
					}
					that.handPOIStatus = false;
				})
				that.positionPicker.start();
			})
		},
		// 点击搜索
		search(){
			this.handPOIStatus = true
			this.POISearch();
		},
		POISearch(){
			let that = this;
			let keyWord = that.pageQuery.address;
			that.placeSearch.search(keyWord, function(status, result) {
				// 查询成功时，result即为对应的POI详情
				console.log(status, result, '关键字')
				if(status == 'complete'){
					let poiList = result.poiList.pois;
					that.poiList = poiList;
					that.handPoi(poiList[0], 0)
					// that.map.setCenter([poiList[0].location.lng, poiList[0].location.lat]);
				}else{
					that.$toast('请稍后重试');
				}
			}) 
		},
		// 点击poi
		handPoi(e, index){
			let that = this;
			that.handPOIStatus = true;
			that.activePOIIndex = index;
			that.pageQuery.province = e.pname ? e.pname : that.pageQuery.province;
			that.pageQuery.city = e.cityname ? e.cityname : that.pageQuery.city;
			that.pageQuery.district = e.adname ? e.adname : that.pageQuery.district;
			that.pageQuery.address = e.name;
			that.pageQuery.lng = e.location.lng;
			that.pageQuery.lat = e.location.lat;
			that.map.setCenter([e.location.lng, e.location.lat]);
		},
		// 确认地址
		affirmAddress(){
			if(this.order){
				let data;
				if(this.pageQuery.type == 1){
					// 救援地
					data = {
						order: this.order,
						theodolitic: this.pageQuery.lng + ',' + this.pageQuery.lat,
						a_province: this.pageQuery.province,
						a_city: this.pageQuery.city,
						a_area: this.pageQuery.district,
						address: this.pageQuery.province + this.pageQuery.city + this.pageQuery.district + this.pageQuery.address
					};
					
				}else{
					// 目的地
					data = {
						order: this.order,
						transit: this.pageQuery.lng + ',' + this.pageQuery.lat,
						d_province: this.pageQuery.province,
						d_city: this.pageQuery.city,
						d_area: this.pageQuery.district,
						destination: this.pageQuery.province + this.pageQuery.city + this.pageQuery.district + this.pageQuery.address
					};
				}
				saveLocation(data).then((res)=>{
					if(res.data.code == 1){
						this.$toast('修改成功！');
						this.$router.go(-1);
					}else{
						this.$toast(res.data.info);
					}
				})
			}else{
				this.$store.commit('SET_LOCATION', this.pageQuery)
				this.$router.go(-1);
			}
		}
	}
}
</script>

<style lang='scss' scoped>
	.pickerAuto{
		position: fixed;
		top: 10000px;
	}
	.location{
		width: 100%;
		height: 100vh;
		display: flex;
		flex-direction: column;
		.affirmBtn{
			position: fixed;
			top: 24px;
			right: 15px;
			width: 56px;
			height: 28px;
			font-size: 14px;
			text-align: center;
			line-height: 28px;
			background: #357EFE;
			color: #FFFFFF;
			border-radius: 4px;
		}
		.locationIcon{
			width: 40px;
			height: 40px;
			border-radius: 10px;
			background: #fff;
			position: fixed;
			top: 180px;
			right: 15px;
			font-size: 20px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.amap{
			height: 378px;
		}
		.list{
			flex: 1;
			border-radius: 10px 10px 0px 0px;
			.hearderSearch{
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 15px;
				box-sizing: border-box;
				.searchBtn{
					width: 32px;
					height: 19px;
					font-size: 14px;
					font-family: PingFangSC-Medium;
					line-height: 19px;
					color: #357EFE;
				}
				.input{
					flex: 1;
					margin-right: 10px;
					// width: 306px;
					height: 38px;
					background: #FCFCFC;
					border-radius: 4px;
					padding: 0 10px;
					box-sizing: border-box;
					border: 0;
					line-height: 38px;
				}
			}
			.pois{
				height: 350px;
				overflow-y: scroll;
				.poi_li{
					padding: 15px 15px 17px 15px;
					box-sizing: border-box;
					display: flex;
					justify-content: space-between;
					align-items: center;
					height: 74px;
					border-bottom: 1px solid #E2E2E2;
					.status{
						img{
							width: 19px;
							height: 13px;
						}
					}
					.poiInfo{
						display: flex;
						align-items: center;
						height: 100%;
						img{
							width: 12px;
							height: 15px;
							margin-right: 11px;
						}
						.info{
							height: 100%;
							display: flex;
							flex-direction: column;
							justify-content: space-between;
							.title{
								font-size: 14px;
								font-family: PingFang SC;
								font-weight: bold;
								line-height: 20px;
								color: #1F2129;
							}
							.address{
								font-size: 11px;
								font-family: PingFang SC;
								font-weight: 400;
								line-height: 16px;
								color: #999999;
							}
							.activePOI{
								color: #357EFE;
								z-index: 99;
							}
						}
					}
				}
			}
		}
	}
</style>
